html,
body {
  @apply m-0 p-0;
}

label span {
  @apply inline-block w-10 mb-2;
}

label span + input {
  @apply ml-4;
}

:root {
  --app-page-transition-delay: 150ms;
  --app-page-transition-time: 150ms;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity var(--app-page-transition-delay) var(--app-page-transition-time) ease-in;
}

.fade-enter-done {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity var(--app-page-transition-time) ease-in;
}

.fade-exit {
  opacity: 1;
}
